import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Button, Box, Grid, IconButton, CardMedia } from '@mui/material';
import theme, {
  COLORS,
  BREAKPOINTS,
  MOBILE_SPACING,
  WIDTH_1024_SPACING,
} from '../utils/theme';
import { GuideCardItems } from '../utils/GuideCardItems';
import { RouteCardItems } from '../utils/RouteCardItems';
import GuideCardGroup from '../components/common/GuideCardGroup';
import RouteCardGroup from '../components/common/RouteCardGroup';
import HomePageImage from '../../static/images/homePage.svg';
import LinkWrapper from '../components/common/Link';
import homePageMessages from '../utils/messages/en/homePageMessages';
import externalLinks from '../../static/images/externalLinks.svg';
import Typography from '../components/common/Typography';
import SEO from '../components/common/Seo';
import globalMessages from '../utils/messages/en/globalMessages';
import ROUTES from '../docs-config/src';
import { readFileSync } from 'fs';
import { replaceRoutes } from '../utils/mapRoutes';
import CarouselComponent from '../components/common/Carousel/index';
import HomePageCard from '../components/common/HomePageCard';
import DEMO_IMAGE from '../../static/images/DemoImage.svg';
import HomePageCardGroup from '../components/common/HomePageCardGroup';
import { UseCaseCardItems } from '../utils/UseCaseCardItems';
import { ByProductCardItems } from '../utils/ByProductCardItems';
import { MostVisitedCardItems } from '../utils/MostVisitedCardItems';
import { FooterCardItems } from '../utils/FooterCardItems';
import NavigationList from '../components/common/NavigationList';
import NavigationListGroup from '../components/common/NavigationListGroup';
import { NavigationListItems } from '../utils/NavigationListItems';
import INFO from '../../static/images/info-fill.svg';

export function getServerData() {
  let type = 'tsx';

  const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
  const sideNavItem = JSON.parse(sideNav);

  const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
  const routes = JSON.parse(route);

  const sideNavItems = replaceRoutes(sideNavItem, routes);
    
  return {
    props: {
    clientSideNavList: sideNavItems || [],
    type: type,
  }
};
}
const useStyles = {
  outerBox: {
    margin: 'auto',
    maxWidth: '1020px',
    [BREAKPOINTS.DEFAULT]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
      maxWidth: `calc( 100% - ${WIDTH_1024_SPACING.MARGIN} )`,
    },
    [BREAKPOINTS.MOBILE]: {
      paddingTop: '12px',
      margin: 'auto 20px',
      maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    },
  },
  announcement: {
    display:'flex',
    backgroundColor: 'rgba(222, 239, 255, 1)',
    padding: '12px',
    gap: '12px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftItem: {
    maxWidth: '475px',
    marginTop: '32px',
    paddingTop:'5%',
    marginRight: '0px',
    [BREAKPOINTS.CUSTOM]: {
      marginTop: '20px',
      maxWidth: '100%'
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '100%',
      paddingBottom:'3%',
      marginBottom: '30px',
    },
    [BREAKPOINTS.MEDIA_MIN_MAX]: {
      maxWidth: '100%',
    },
    [BREAKPOINTS.MEDIA_MIN_1367]: {
      maxWidth: '90%',
    },
  },
  leftContainer: {
    [BREAKPOINTS.DESKTOP]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection:'column',
    },
    [BREAKPOINTS.CUSTOM]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection:'column',
    },
    [BREAKPOINTS.MEDIA_MIN_MAX]: {
      justifyContent: 'center',
    },
    [BREAKPOINTS.MEDIA_MIN_1367]: {
      justifyContent: 'center',
    },
  },
  titleStyle: {
    marginBottom: '22px',
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '12px',
    },
  },
  descriptionStyle: {
    marginBottom: '22px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    [BREAKPOINTS.MOBILE]: {
      marginBottom: '32px',
    },
  },
  buttonGroupStyle: {
    display: 'flex',
    alignItems: 'center',
    [BREAKPOINTS.MOBILE]: {
      margin: '32px 0px 0px 0px',
    },
  },
  QuickstartButtonStyle: {
    borderRadius: '24px',
    padding: '12px 24px',
    backgroundColor: COLORS.TEXT_HIGH_EMPHASIS,
  },
  integrateButtonStyle: {
    marginLeft:'10px',
    borderRadius: '24px',
    padding: '12px 24px',
    border: '2px solid' + COLORS.TEXT_HIGH_EMPHASIS,
    backgroundColor: 'transparent'
  },
  externalLinkIcon: {
    width: '16px',
    height: '16px',
  },
  carouselStyle: {
    paddingTop: '35px',
    width: '510px',
    [BREAKPOINTS.DESKTOP]: {
      width: '751px',
    },
    [BREAKPOINTS.MEDIA_MIN_MAX]: {
      width: '90%',
    },
    [BREAKPOINTS.MEDIA_MIN_1367]: {
      width: '90%',
    },
  },
  homeIllustration: {
    [BREAKPOINTS.DESKTOP]: {
      marginLeft: '3%',
    },
    [BREAKPOINTS.CUSTOM]: {
      display: 'flex',
      justifyContent: 'center'
    },
  },
  apiDocsTextStyle: {
    color: theme.palette.primary.main,
  },
  apiDocsStyle: {
    display: 'flex',
    textDecoration: 'underline',
    marginLeft: '20px',
    alignItems: 'center',
    textDecorationColor: theme.palette.primary.main,
  },
  headerStyle: {
    marginTop: '54px',
    [BREAKPOINTS.MOBILE]: {
      marginTop: '32px',
      marginBottom: '0px',
    },
  },
  subTitleStyle: {
    marginTop: '2px',
    color: COLORS.TEXT_MEDIUM_EMPHASIS,
    marginBottom: '24px',
  },
  iconButton: {
    padding: '0px',
    paddingLeft: '8px',
  },
  quickStartStyle: {
    color: theme.palette.common.white,
  },
  buttonStyle: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
  },
  gridStyle: {
    justifyContent: 'space-between',
    marginTop: '22px',
    [BREAKPOINTS.DESKTOP]: {
      justifyContent: 'center',
    },
    [BREAKPOINTS.MEDIA_MIN_MAX]: {
      justifyContent: 'center',
    },
    [BREAKPOINTS.MEDIA_MIN_1367]: {
      justifyContent: 'center',
    },
  },
  demoCardStyle: {
    paddingTop:'30px',
  },
  mostVisitedCardStyle: {
    paddingTop:'30px',
    paddingBottom:'30px'
  }
};

const IndexPage = () => {
  if (typeof window !== 'undefined') {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  }
  return (
    <main>
      <SEO
        title={homePageMessages.welcome}
        description={homePageMessages.description}
      />
      <ThemeProvider theme={theme}>
        {homePageMessages.announcement && 
          <Box sx={useStyles.announcement}>
            <img src={INFO} alt='img'/>
            <Typography
              type="ANNOUNCEMENT_TITLE"
            >
              {homePageMessages.announcement}
            </Typography>
          </Box>}
        <Box sx={useStyles.outerBox}>
          <Grid container sx={useStyles.gridStyle}>
            <Grid item sx={useStyles.leftItem}>
              <Grid container sx={useStyles.leftContainer}>
                <Grid item>
              <Typography type="DESKTOP_BODY_TEXT" sx={useStyles.titleStyle}>
                {homePageMessages.welcome}
              </Typography>
              </Grid>
              <Grid item>
              <Typography
                type="DESKTOP_BODY_SUBTITLE"
                sx={useStyles.descriptionStyle}
              >
                {homePageMessages.description}
              </Typography>
              </Grid>
              <Grid item>
              <Box style={useStyles.buttonGroupStyle}>
                <LinkWrapper href={ROUTES.productGetStarted}>
                  <Button
                    variant="contained"
                    style={useStyles.QuickstartButtonStyle}
                  >
                    <Typography
                      type="DESKTOP_BUTTON_TEXT"
                      sx={useStyles.quickStartStyle}
                    >
                      {homePageMessages.quickStart}
                    </Typography>
                  </Button>
                </LinkWrapper>
                <LinkWrapper href={ROUTES.productExplore}>
                  <Button
                    disableRipple
                    variant="outlined"
                    style={useStyles.integrateButtonStyle}
                  >
                    <Typography
                      type="DESKTOP_BUTTON_TEXT"
                      sx={useStyles.buttonStyle}
                    >
                      {homePageMessages.explore}
                    </Typography>
                  </Button>
                </LinkWrapper>
              </Box>
              </Grid>
            </Grid>
            </Grid>
            <Grid item sx={useStyles.carouselStyle}>
              <CarouselComponent />
            </Grid>
          </Grid>
          <Box>
            <Typography type='DESKTOP_HEADING' sx={useStyles.headerStyle}>
              {homePageMessages.usecase}
            </Typography>
          </Box>
          <HomePageCardGroup items={UseCaseCardItems} type='useCase'/>
          <Box>
            <Typography type='DESKTOP_HEADING' sx={useStyles.headerStyle}>
              {homePageMessages.byProduct}
            </Typography>
          </Box>
          <HomePageCardGroup items={ByProductCardItems} type='byProduct'/>
          <Box>
            <Typography type='DESKTOP_HEADING' sx={useStyles.headerStyle}>
              {homePageMessages.mostVisited}
            </Typography>
          </Box>
          <HomePageCardGroup items={MostVisitedCardItems} type='byProduct'/>
          <Box sx={useStyles.demoCardStyle}>
            <NavigationListGroup items={NavigationListItems} />
          </Box>
          <Box sx={useStyles.mostVisitedCardStyle}>
            <HomePageCardGroup items={FooterCardItems} type='footer'/>
          </Box>
        </Box>
      </ThemeProvider>
    </main>
  );
};

export default IndexPage;
